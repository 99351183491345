<div class="PivotTable-body" [bentoBusyLoader]="isDataLoading">
  <wj-flex-grid
    #flexGrid
    [ngClass]="{
      DataGrid: true,
      'DataGrid--views--pivot-unfroze': !frozenColumnCount,
      'DataGrid--views--pivot': true
    }"
    headersVisibility="Column"
    [itemsSource]="mainData"
    [allowMerging]="'ColumnHeaders'"
    [alternatingRowStep]="0"
    [autoGenerateColumns]="false"
    [allowDragging]="false"
    [isReadOnly]="true"
    [frozenColumns]="4"
    [columnGroups]="columnGroups"
    [autoRowHeights]="true"
    [itemFormatter]="itemFormatter"
    [showMarquee]="true"
    [virtualizationThreshold]="5000"
    [headersFocusability]="'All'"
    (initialized)="onInitialized(flexGrid)"
    (gotFocus)="focusOnGrid(flexGrid, $event)"
    (keydown)="handleKeyDown(flexGrid, $event)"
    (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
  ></wj-flex-grid>
  <ng-template #listOfFirmsModal let-c="close" let-d="dismiss">
    <button type="button" class="close" aria-label="Close" (click)="closePGPopup()">
      <i class="bento-icon-close-x" aria-hidden="true"></i>
    </button>
    <h3 id="modalPeerGroup" class="modal-header h2">
      {{ peerGroupDetail.selectedPeerGroup.name }}
    </h3>
    <div class="modal-body">
      <ol class="OrderedList" *ngIf="!peerGroupDetail.is_ppgRequest">
        <li *ngFor="let firm of peerGroupDetail.selectedFirms; index as sequence">{{ firm.name }}</li>
      </ol>
      <ol class="OrderedList" *ngIf="peerGroupDetail.is_ppgRequest">
        <li *ngFor="let criteria of peerGroupDetail.ppgCriteria">{{ criteria }}</li>
      </ol>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="closePGPopup()">Close</button>
    </div>
  </ng-template>
</div>
