import {Component, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {BaseService} from 'src/app/core/services/base/base.service';
import FirmBasicsModel from '../../../models/firm-basics.model';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {RatesReportRequest} from 'src/app/core/models/rates-report-request.model';
import {FixedTimeRange, RatesReportModal} from 'src/app/core/models/rates-report.model';
import {environment} from 'src/environments/environment';
import {PeerCheckServiceService} from '../../peer-check/peer-check/peer-check-service.service';
import {BentoAlertItemOptions, BentoComboboxOptions} from '@bento/bento-ng';
import {BfmFieldEvent} from '@bento/bfm-ng';
import {NgForm} from '@angular/forms';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-new-rates-report',
  templateUrl: './new-rates-report.component.html',
  styleUrls: ['./new-rates-report.component.scss'],
})
export class NewRatesReportComponent implements OnInit {
  ratesReportData: RatesReportModal = new RatesReportModal();
  ratesReportRequest: RatesReportRequest = new RatesReportRequest();
  ratesReportModal: RatesReportModal = new RatesReportModal();
  firmBasicData = new FirmBasicsModel();
  rollingTimeRangeItemsStream: BehaviorSubject<any>;
  currencyStream: BehaviorSubject<any>;
  firmsArr: BehaviorSubject<string[]>;
  arr: any;
  currencyArr: any[] = [];
  disableGranularReport: boolean = true;
  searchString: any = '';
  dataLoading = false;
  dataStream: BehaviorSubject<any>;
  extractedYYYYMM = {
    year: null,
    month: null,
  };
  validations: any;
  validatePattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\\.(?:.[a-zA-Z0-9-]+)*$";
  validatePattern1 = '^(0[1-9]|10|11|12)/([0-9]{4})$';
  @Output() reportsClick = new EventEmitter();
  successMsg = '';
  errorMessage = '';
  @ViewChild('ratesForm') ratesForm: NgForm;
  alerts: BentoAlertItemOptions[] = [];

  comboboxOptions1: BentoComboboxOptions = {
    searchable: true,
    autoSelect: false,
  };

  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    autoSelect: true,
    searchCompare: (row, search) => {
      this.searchString = search;
      const searchLowerCase = search.toLocaleLowerCase();
      const name = `${row.name}`.toLowerCase();

      return name.indexOf(searchLowerCase) > -1;
    },
    labelFormatter: (row) => `${row.name}`,
  };

  constructor(
    private _router: Router,
    private service: BaseService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService,
    public _service: PeerCheckServiceService,
    private featureToggleService: FeatureToggleService,
    private globalservice: GlobalService
  ) {
    this.validations = {
      validatePattern: {
        pattern: 'Error:  Enter valid email address',
      },
      validatePattern1: {
        pattern: 'Error:  Enter valid date format',
      },
    };
    this.ratesReportData = new RatesReportModal();
    this.ratesReportData.fixedTimeRange = new FixedTimeRange();
  }

  async ngOnInit() {
    /*************************************************/
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - DISABLE_OLD_VIZ_GRID ******/
    await this.featureToggleService
      .getFeatureValue('ENABLE_GRANULAR_REPORTS')
      .then((val) => (this.disableGranularReport = val));
    /*************************************************/
    this.firmBasicData = await this._service.getFirmBasicsData(this.getDefaultOrSelectedFirmId);
    if (this.firmBasicData.yyyymm) {
      this.extractedYYYYMM.year = this.firmBasicData.yyyymm.toString().substring(0, 4);
      this.extractedYYYYMM.month = this.firmBasicData.yyyymm.toString().substring(4, 6);
    }
    this.getAllFirms();
    this.getAllCurrency();

    //this.dataStream = new BehaviorSubject(this.currencyArr);
  }

  onBillableSelected() {
    //this.ratesReportData.matterTypes.push('billable')
  }
  currencySelected(v: any) {
    this.ratesReportModal.currency = v;
  }
  timeRangeOnItemsSelected(v: any) {
    if (v && v.id == 0) {
      this.ratesReportModal.timeRangeSelected = 'Year To Date(YTD)';
    } else {
      if (v) {
        this.ratesReportModal.timeRangeSelected = 'Rolling';
      }
    }
    this.ratesReportModal.rollingTimeRangeSelectedItems = v;
  }

  async onFirmChange() {
    this.firmBasicData = await this._service.getFirmBasicsData(
      this.ratesReportModal.firm.id || this.getDefaultOrSelectedFirmId
    );
    this.ratesReportModal.peerGroups = [];
    this.ratesReportModal.practices = [];
    this.ratesReportModal.offices = [];
    this.populateInputValues(this.ratesReportModal.firm.id);
    this.searchString = '';
  }

  generateRequestBody() {
    const startDate = new Date();
    const endDate = new Date();
    const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
    const month = this.firmBasicData.yyyymm.toString().slice(-2);
    startDate.setDate(1);
    endDate.setDate(1);
    endDate.setFullYear(parseInt(year));
    endDate.setMonth(parseInt(month) - 1);
    startDate.setFullYear(parseInt(year));

    this.ratesReportModal.startPeriod = startDate.getFullYear() + '01';
    let lastMonth = endDate.getMonth() +1;
    this.ratesReportModal.endPeriod = endDate.getFullYear() + ('0' + lastMonth).slice(-2);

    if (this.ratesReportModal.isBillable && this.ratesReportModal.isContingent) {
      this.ratesReportModal && this.ratesReportModal.matterTypes && this.ratesReportModal.matterTypes.push('all');
    } else if (this.ratesReportModal.isBillable) {
      this.ratesReportModal.matterTypes.push('billable');
    } else if (this.ratesReportModal.isContingent) {
      this.ratesReportModal.matterTypes.push('contingent');
    }
    if (this.ratesReportModal.isContractorInclude) {
      this.ratesReportModal.contractors.push('include');
    } else if (!this.ratesReportModal.isContractorInclude) {
      this.ratesReportModal && this.ratesReportModal.contractors && this.ratesReportModal.contractors.push('exclude');
    }
    if (this.ratesReportModal.isGranular) {
      this.ratesReportModal.reportType = 'granular';
    } else if (!this.ratesReportModal.isGranular) {
      this.ratesReportModal.reportType = 'default';
    }

    return this.ratesReportModal;
  }

  onGetReport() {
    const generatePayload: any = this.generateRequestBody();
    if (this.ratesReportModal.isRollingTimeRange) {
      if (this.ratesReportModal.timeRangeSelected == 'Rolling') {
        generatePayload['rollingPeriodId'] =
          this.ratesReportModal &&
          this.ratesReportModal.rollingTimeRangeSelectedItems &&
          this.ratesReportModal.rollingTimeRangeSelectedItems.id;
        generatePayload['startPeriod'] = this.ratesReportModal.rollingTimeRangeSelectedItems.startPeriod1;
        generatePayload['endPeriod'] = this.ratesReportModal.rollingTimeRangeSelectedItems.endPeriod1;
      } else {
        const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
        const month = this.firmBasicData.yyyymm.toString().slice(-2);
        let firstDay_startDate = new Date();
        let lastDay_endDate = new Date();
        firstDay_startDate.setDate(1);
        firstDay_startDate.setMonth(1);
        firstDay_startDate.setFullYear(parseInt(year));
        lastDay_endDate.setDate(1);
        lastDay_endDate.setMonth(parseInt(month) - 1);
        lastDay_endDate.setFullYear(parseInt(year));
        generatePayload['rollingPeriodId'] = this.ratesReportModal.rollingTimeRangeSelectedItems.id;
        generatePayload['startPeriod'] =
          firstDay_startDate.getFullYear() + ('0' + firstDay_startDate.getMonth()).toString().slice(-2);
        let lastMonth = lastDay_endDate.getMonth() +1;
        generatePayload['endPeriod'] =
          lastDay_endDate.getFullYear() + ('0' + lastMonth).toString().slice(-2);
      }
    } else {
      generatePayload['startPeriod'] = this.ratesReportModal.fixedTimeRange.startPeriod1;
      generatePayload['endPeriod'] = this.ratesReportModal.fixedTimeRange.endPeriod1;
      generatePayload['rollingPeriodId'] = 9;
    }
    const requestBody = {};
    requestBody['contractors'] = generatePayload['contractors'];
    requestBody['currency'] = generatePayload['currency'];
    requestBody['email'] = generatePayload['email'];
    requestBody['endPeriod'] = generatePayload['endPeriod'];
    requestBody['startPeriod'] = generatePayload['startPeriod'];
    requestBody['matterTypes'] = generatePayload['matterTypes'];
    requestBody['offices'] = this.ratesReportRequest.office;
    requestBody['practices'] = this.ratesReportRequest.practiceGroup;
    requestBody['peerGroups'] = this.ratesReportRequest.peerGroup;
    requestBody['reportType'] = generatePayload['reportType'];
    requestBody['rollingPeriodId'] = generatePayload['rollingPeriodId'];

    this.service
      .post(
        environment.FIAdminBaseEndpoint + 'v1/ratesReport/firmId/' + this.ratesReportModal.firm.id + '/submit',
        requestBody
      )
      .subscribe(
        (result) => {
          this.ratesForm.form.markAsUntouched();
          this.ratesReportModal = new RatesReportModal();
          this.ratesReportModal.firm = this.arr[0];
          this.populateInputValues(this.arr[0].id);
          this.ratesReportModal.currency = 'USD';
          this.successMsg = 'Your report request successfully submitted.';
          //role changes only when result is success.
          changeRoleOfBentoAlert();
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 20000,
            closeable: true,
          });
          //this.ratesReportModal.currency = 'USD';
        },
        (error) => {
          if (error.error.message) {
            this.errorMessage = error.error.message;
          } else {
            this.errorMessage = 'Something went wrong, please try again.';
          }

          this.alerts.push({
            type: 'warning',
            msg: this.errorMessage,
            closeable: true,
          });
        }
      );
    // Function to change the role attribute of the element
    function changeRoleOfBentoAlert() {
      // Create a MutationObserver to watch for changes in the DOM
      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const bentoAlertElement = document.querySelector(
              'div.alert.bento-alert.alert-success.alert-dismissable'
            ) as HTMLElement;
            if (bentoAlertElement && bentoAlertElement.getAttribute('role') === 'alert') {
              bentoAlertElement.setAttribute('aria-live', 'polite');
              // Delay to ensure screen reader notices the change
              setTimeout(() => {
                bentoAlertElement.setAttribute('role', 'status');
              }, 100);
              observer.disconnect();
              break;
            }
          }
        }
      });

      // Start observing the document body for child elements being added
      observer.observe(document.body, {childList: true, subtree: true});
    }
  }

  handleFixedDateRangeSelection() {
    if (
      !(
        this.ratesReportModal &&
        this.ratesReportModal.fixedTimeRange &&
        this.ratesReportModal.fixedTimeRange.startDateText &&
        this.ratesReportModal.fixedTimeRange.endDateText
      )
    ) {
      return;
    }
    let startDateText = this.ratesReportModal.fixedTimeRange.startDateText;
    let endDateText = this.ratesReportModal.fixedTimeRange.endDateText;

    if (
      this.ratesForm &&
      this.ratesForm.form &&
      this.ratesForm.form.controls.startDate.valid &&
      this.ratesForm.form.controls.endDate.valid
    ) {
      let startDateMatch = startDateText.match(this.validatePattern1);
      let endDateMatch = endDateText.match(this.validatePattern1);

      let period = this.peercheckMessageHandlerService.getPeriod(null, null, null, false, startDateMatch, endDateMatch);
      if (Number(period[0]) <= Number(period[1])) {
        this.ratesReportModal.fixedTimeRange.startPeriod1 = period[0];
        this.ratesReportModal.fixedTimeRange.endPeriod1 = period[1];
        //this.viewGridComponent.updateSelection();
      }
    }
  }

  isValidDate(evt: BfmFieldEvent, sourceElement: string, formData: any): void {
    if (!evt) {
      return;
    }
    const setValidity = evt.setValidity;
    if (
      (sourceElement == 'EndDate' && formData.form.controls.startDate.value) ||
      (sourceElement == 'StartDate' && formData.form.controls.endDate.value)
    ) {
      const fg = evt.formControl;
      let endDate = formData.form.controls.endDate.value;
      let startDate = formData.form.controls.startDate.value;
      let startDateMatch = startDate.match(this.validatePattern1);
      let endDateMatch = endDate.match(this.validatePattern1);
      let isBeyondEndDateExtractDate = this.checkEndDateWithExtractDate(endDateMatch);
      if (
        !(
          startDateMatch &&
          endDateMatch &&
          (Number(startDateMatch[2]) < Number(endDateMatch[2]) ||
            (Number(startDateMatch[2]) == Number(endDateMatch[2]) &&
              Number(startDateMatch[1]) <= Number(endDateMatch[1])))
        )
      ) {
        setTimeout(() => {
          return setValidity({valid: false, message: 'Start Date should be less than End Date'});
        }, 0);
      } else {
        if (sourceElement == 'EndDate' && isBeyondEndDateExtractDate) {
          formData.form.get('startDate').updateValueAndValidity();
          setTimeout(() => {
            return setValidity({
              valid: false,
              message: `End date cannot be beyond ${this.extractedYYYYMM.month}/${this.extractedYYYYMM.year}.`,
            });
          }, 0);
        } else {
          setValidity({valid: true});
          let period = this.peercheckMessageHandlerService.getPeriod(
            null,
            null,
            null,
            false,
            startDateMatch,
            endDateMatch
          );
          this.ratesReportModal.fixedTimeRange.startPeriod1 = period[0];
          this.ratesReportModal.fixedTimeRange.endPeriod1 = period[1];
          this.ratesForm = formData;
          if (sourceElement == 'EndDate' && formData.form.controls.startDate.status == 'INVALID') {
            return formData.form.get('startDate').updateValueAndValidity();
          } else if (sourceElement == 'StartDate' && formData.form.controls.endDate.status == 'INVALID') {
            return formData.form.get('endDate').updateValueAndValidity();
          }
        }
      }
    } else {
      if (sourceElement == 'EndDate' && formData.form.controls.endDate.value) {
        let endDate = formData.form.controls.endDate.value;
        let endDateMatch = endDate.match(this.validatePattern1);
        let isBeyondEndDateExtractDate = this.checkEndDateWithExtractDate(endDateMatch);
        if (isBeyondEndDateExtractDate) {
          formData.form.get('startDate').updateValueAndValidity();
          setTimeout(() => {
            return setValidity({
              valid: false,
              message: `End date cannot be beyond ${this.extractedYYYYMM.month}/${this.extractedYYYYMM.year}.`,
            });
          }, 0);
        }
        return;
      } else {
        return;
      }
    }
  }

  onCancel() {
    this.reportsClick.emit();
  }

  onPeerGroupSelected(peerGroups: any[]) {
    this.ratesReportRequest.peerGroup = [];
    if (peerGroups.length == 1) {
      this.ratesReportData.selectedPeerGroupText = '1 peer group selected';
    } else if (peerGroups.length == this.ratesReportData.peerGroups.length) {
      this.ratesReportData.selectedPeerGroupText = 'All';
    } else this.ratesReportData.selectedPeerGroupText = peerGroups.length.toString() + ' peer groups selected';
    for (var peerGroup of peerGroups) {
      this.ratesReportRequest.peerGroup.push(peerGroup.id);
    }
  }

  onPracticeGroupSelected(practiceGroups: any[]) {
    this.ratesReportRequest.practiceGroup = [];
    if (practiceGroups.length == 1) {
      this.ratesReportData.selectedPracticeGroupText = '1 practice group selected';
    } else if (practiceGroups.length == this.ratesReportData.practices.length) {
      this.ratesReportData.selectedPracticeGroupText = 'All';
    } else
      this.ratesReportData.selectedPracticeGroupText = practiceGroups.length.toString() + ' practice groups selected';
    for (var practiceGroup of practiceGroups) {
      this.ratesReportRequest.practiceGroup.push(practiceGroup.id);
    }
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : this.route.snapshot.params.id;
  }

  onOfficeSelected(offices: any[]) {
    this.ratesReportRequest.office = [];
    if (offices.length == 1) {
      this.ratesReportData.selectedOfficeText = '1 office selected';
    } else if (offices.length == this.ratesReportData.offices.length) {
      this.ratesReportData.selectedOfficeText = 'All';
    } else this.ratesReportData.selectedOfficeText = offices.length.toString() + ' offices selected';
    for (var office of offices) {
      this.ratesReportRequest.office.push(office.id);
    }
  }
  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  populateTimeRange(rollingTimeRange) {
    let periods = [];
    let currentDate = new Date();
    rollingTimeRange.forEach((element) => {
      currentDate = new Date();
      if (this.firmBasicData.yyyymm) {
        let yyyymm = this.firmBasicData.yyyymm.toString();
        let yyyymmAry = yyyymm.replace(/(.{4})/g, '$1-').split('-');
        currentDate.setFullYear(Number(yyyymmAry[0]));
        currentDate.setDate(1);
        currentDate.setMonth(Number(yyyymmAry[1]) - 1);
      }
      periods = this.peercheckMessageHandlerService.getPeriod(
        null,
        currentDate,
        this.peercheckMessageHandlerService.getMonthForTimeRange(element.name),
        true,
        null,
        null
      );
      element['startPeriod1'] = periods[1];
      element['endPeriod1'] = periods[0];
    });
    this.rollingTimeRangeItemsStream = new BehaviorSubject(rollingTimeRange);
  }

  getAllFirms() {
    this.dataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firms', '').subscribe((data) => {
      this.dataLoading = false;
      this.arr = data.firms;
      this.firmsArr = new BehaviorSubject(
        (() => {
          const a = [];

          for (let i = 0; i < data.firms.length; i++) {
            a.push(data.firms[i]);
          }
          return a;
        })()
      );

      this.ratesReportModal.firm = this.arr[0];
      this.populateInputValues(this.arr[0].id);
    });
  }
  openChangeEvent(isOpen: any) {
    if (isOpen) {
      this.ratesReportModal.firm = null;
    } else {
      if (this.searchString == '') {
        setTimeout(() => {
          if (this.ratesReportModal.firm == null) {
            this.ratesReportModal.firm = this.arr[0];

            this.populateInputValues(this.arr[0].id);
          }
        }, 100);
      }
    }
  }

  getAllCurrency() {
    this.dataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/currency', '').subscribe((data) => {
      this.dataLoading = false;
      this.currencyArr = data;
      this.currencyStream = new BehaviorSubject(
        (() => {
          const a = [];
          for (let i = 0; i < this.currencyArr.length; i++) {
            a.push(this.currencyArr[i].currencyCode);
          }
          return a;
        })()
      );
      this.ratesReportModal.currency = 'USD';
    });
  }

  populateInputValues(firm_id = null) {
    const firmId = firm_id ? firm_id : this.getDefaultOrSelectedFirmId;
    //this.isDataLoading = true;
    //this.isInputPanelDataLoading = true;
    this.dataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/view/values/' + firmId, '').subscribe((result) => {
      this.dataLoading = false;
      if (result && result.offices) {
        this.ratesReportData.offices = result.offices;
        this.ratesReportData.officeStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (let i = 0; i < result.offices.length; i++) {
              a.push({...result.offices[i]});
            }
            return a;
          })()
        );
      }
      if (result && result.practices) {
        this.ratesReportData.practices = result.practices;
        this.ratesReportData.practiceGroupStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (let i = 0; i < result.practices.length; i++) {
              a.push({...result.practices[i]});
            }
            return a;
          })()
        );
      }
      if (result && result.peerGroups) {
        this.ratesReportData.peerGroups = result.peerGroups;
        this.ratesReportData.peerGroupStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (let i = 0; i < result.peerGroups.length; i++) {
              a.push({...result.peerGroups[i]});
            }
            return a;
          })()
        );
      }
      if (result && result.rollingFormats) {
        this.populateTimeRange(result.rollingFormats);
      }
      this.getAllCurrency();
      this.globalservice.skipFocusTwiceCombobox();
    });
  }
  checkEndDateWithExtractDate(endDateMatch: number[]) {
    if (endDateMatch) {
      let extractDate = new Date();
      let endDateFormat = new Date();
      endDateFormat.setFullYear(endDateMatch[2], endDateMatch[1], 1);
      extractDate.setFullYear(parseInt(this.extractedYYYYMM.year), parseInt(this.extractedYYYYMM.month), 1);
      return endDateFormat.getTime() > extractDate.getTime();
    }
    return false;
  }
}
