import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import $ from 'jquery';
@Component({
  selector: 'app-rename-custom-peergroup',
  templateUrl: './rename-custom-peergroup.component.html',
  styleUrls: ['./rename-custom-peergroup.component.scss'],
})
export class RenameCustomPeergroupComponent {
  peerGroupName: string = '';
  @Input() peerGroup: any;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.peerGroupName = this.peerGroup.pgname;
    setTimeout(() => {
      $('.close').focus();
    }, 100);
  }
}
