<div
  role="dialog"
  tabindex="-1"
  aria-modal="true"
  aria-labelledby="modalSaveView"
  class="modal-content"
  *ngIf="!isCalledForVizOrView"
  [bentoBusyLoader]="isDataLoading"
>
  <button
    type="button"
    class="close"
    id="closeButton"
    aria-label="Close dialog"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalSaveView" class="modal-header h2">Save view</h3>
  <div class="modal-body">
    <bento-alert [inline]="true" [items]="alerts"></bento-alert>
    <span class="label-required-summary">Required field</span>
    <div class="form-group">
      <label for="viewName" class="bento-label-required">Name view</label>
      <input
        type="text"
        name="viewName"
        id="viewName"
        class="form-control"
        required
        [(ngModel)]="saveViewModel.viewName"
      />
    </div>
    <div class="form-group" *ngIf="!saveViewModel.isContainsPPG">
      <label for="shareUser">Share view</label>
      <app-bento-custom-multiselect-overlay
        name="shareUser"
        id="shareUser"
        class="FormMultiselect"
        [ngModel]="saveViewModel.shareUserSelectedItems"
        (ngModelChange)="shareUserOnItemsSelected($event)"
        [itemsObservable]="shareUserItemsStream"
        [overlayMaxHeight]="400"
        [editButtonText]="shareUserSelectedText"
        [ariaLabelValue]="'share view'"
      ></app-bento-custom-multiselect-overlay>
    </div>
    <ng-container *ngIf="saveViewModel.isContainsPPG">
      <bento-alert [inline]="true" [items]="warningAlerts"></bento-alert>
    </ng-container>
    <div class="form-group">
      <bento-checkbox id="defaultForMe" [(ngModel)]="saveViewModel.isDefaultForMe"></bento-checkbox>
      <label for="defaultForMe" class="font-weight-normal">Make view my default</label>
    </div>
    <div class="form-group" *ngIf="!saveViewModel.isContainsPPG && !isGUser">
      <bento-checkbox
        [(ngModel)]="saveViewModel.isDefaultForUser"
        id="defaultForUsers"
        [disabled]="isUserSelected"
      ></bento-checkbox>
      <label for="defaultForUsers" class="font-weight-normal">Make view default for selected users</label>
    </div>
  </div>
  <div class="modal-footer modal-footer--custom a11y">
    <button
      aria-label="Save view"
      type="button"
      class="btn btn-primary"
      [attr.aria-disabled]="!saveViewModel.viewName && viewData ? 'true': 'false' "
      (click)="!saveViewModel.viewName && viewData ? $event.stopPropagation() :saveSelection()"
      [attr.aria-labelledby]="!saveViewModel.viewName && viewData ? 'aria-required-save-view': null"
    >
      Save
    </button>
    <button
      aria-label="Cancel view"
      type="button"
      class="btn btn-outline-secondary"
      (click)="activeModal.dismiss('Cancel')"
    >
      Cancel
    </button>
  </div>
  <span class="sr-only" id="aria-required-save-view">Fill required fields, Save </span>
</div>

<div
  tabindex="-1"
  class="modal-content save-vs-modal-content"
  *ngIf="isCalledForVizOrView"
  [bentoBusyLoader]="isDataLoading"
>
  <button
    type="button"
    class="close"
    id="closeButton"
    aria-label="Close dialog"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h2 id="modalSaveVisualization" class="modal-header h2">Save visualization</h2>
  <div class="modal-body">
    <bento-alert [inline]="true" [items]="alerts"></bento-alert>
    <span class="label-required-summary">Required field</span>
    <div class="form-group">
      <label for="visualizationName" class="bento-label-required">Name visualization</label>
      <input
        type="text"
        name="visualizationName"
        id="visualizationName"
        class="form-control"
        required
        [(ngModel)]="visualizationModel.name"
      />
    </div>
    <div class="form-group">
      <bento-checkbox
        id="makeFavorite"
        [(ngModel)]="visualizationModel.favorite"
        [attr.aria-label]="'Make visualization favorite'"
      ></bento-checkbox>
      <label for="makeFavorite" class="font-weight-normal">Make visualization favorite</label>
    </div>
  </div>
  <div class="modal-footer modal-footer--custom a11y">
   
      <button
        aria-label="Save Visualization"
        type="button"
        class="btn btn-primary"
        (click)="saveVisualization()"
        [attr.aria-disabled]="
          !visualizationModel ||
          !visualizationModel.name ||
          (isVisualizationTemplate && visualizationModel.name === visualizationModel.orignalName) ?
          'true' : 'false'
        "
        [attr.aria-labelledby]="
        !visualizationModel ||
        !visualizationModel.name ||
        (isVisualizationTemplate && visualizationModel.name === visualizationModel.orignalName) ?
        'aria-required-save' : null
      "
      >
        Save
      </button>
    <button
      aria-label="Cancel Visualization"
      type="button"
      class="btn btn-outline-secondary"
      (click)="activeModal.dismiss('Cancel')"
    >
      Cancel
    </button>
    <span class="sr-only" id="aria-required-save">Fill required values, Save</span>
  </div>
</div>
