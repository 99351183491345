import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AxeToolCommonMethodsService {
  constructor() {}

  addAttributeToElement(querySelector, attributeToBeAdded, valueOfAttribute) {
    const elements1 = document.querySelectorAll(querySelector);
    elements1.forEach((elements1) => {
      elements1.setAttribute(attributeToBeAdded, valueOfAttribute);
    });
  }
  removeAttributeToElement(querySelector, attributeToBeRemoved) {
    const elements1 = document.querySelectorAll(querySelector);
    elements1.forEach((elements1) => {
      elements1.removeAttribute(attributeToBeRemoved);
    });
  }
  getHTMLElement(querySelector) {
    const elements = document.querySelector(querySelector);
    return elements;
  }
  enableFocusFromCloumnAndRowHeaders(event, focusableElements:string){
     // Find all focusable elements
     const allFocusable = Array.from(
      document.querySelectorAll(
        focusableElements
      )
    ) as HTMLElement[];

    const currentIndex = allFocusable.indexOf(event.target as HTMLElement);
     // Handle Tab: Focus the next element
    
     if (event.shiftKey) {
      // Handle Shift+Tab: Focus the previous element
      if (currentIndex > 0) {
        const previousElement = allFocusable[currentIndex - 1];
        previousElement.focus();
      }
      event.preventDefault(); // Prevent default Tab behavio
    } else {
      // Handle Tab: Focus the next element
      if (currentIndex >= 0 && currentIndex + 1 < allFocusable.length 
        && currentIndex + 1 != allFocusable.length) {
        event.preventDefault(); // Prevent default Tab behavio
        const nextElement = allFocusable[currentIndex + 1];
        nextElement.focus();

      }
    }
  }
}
