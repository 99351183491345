<form *ngIf="showNewFilter" name="DateRangeForm" #DateRangeForm="ngForm" bfmForm>
  <div class="form-group FormRange" *ngIf="!isCustomRange">
    <div class="FormRange-group">
      <div class="FormRange-item">
        <label for="{{ id + 'Year' }}" id="timeRangeStartLabel">
          <div>Year*</div>
        </label>
        <bento-combobox
          id="{{ id + 'Year' }}"
          [attr.name]="name + 'Year'"
          [name]="name + 'Year'"
          [(ngModel)]="dateRangeField.years"
          (ngModelChange)="handleYearSelection()"
          [itemsObservable]="dateRangeYearsStream"
          [options]="comboboxOptions"
          [placeholder]="'Select year'"
          [disabled]="disabled"
          aria-required="true"
          required
          bfmField
          bfmLabel="Year"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeStartLabel'"
        ></bento-combobox>
      </div>
      <div class="FormRange-item" *ngIf="shouldShowMonth">
        <label for="{{ id + 'Month' }}" id="timeRangeEndLabel">
          <div>Month*</div>
        </label>
        <bento-combobox
          id="{{ id + 'Month' }}"
          [attr.name]="name + 'Month'"
          [name]="name + 'Month'"
          [(ngModel)]="dateRangeField.month"
          (ngModelChange)="handleMonthSelection()"
          [itemsObservable]="dateRangeMonthStream"
          [options]="comboboxOptions"
          [placeholder]="'Select month'"
          [disabled]="disabled"
          aria-required="true"
          required
          bfmField
          bfmLabel="Month"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeEndLabel'"
        ></bento-combobox>
      </div>
      <div class="FormRange-item" *ngIf="shouldShowQuarter">
        <label for="quater" id="timeRangeEndLabel">
          <div>Quarter*</div>
        </label>
        <bento-combobox
          [attr.id]="id + 'Quarter'"
          [attr.name]="name + 'Quarter'"
          [name]="name + 'Quarter'"
          [(ngModel)]="dateRangeField.quarter"
          [itemsObservable]="dateRangeQuarterStream"
          [options]="comboboxOptions"
          [placeholder]="'Select quarter'"
          [disabled]="disabled"
          aria-required="true"
          required
          bfmField
          bfmLabel="Quarter"
        ></bento-combobox>
      </div>
    </div>
    <!-- <div class="form-group Form-group Form-group--nested">
      <label class="bento-label-required" [attr.for]="id + 'Year'">Year</label>
      <bento-combobox
        [attr.id]="id + 'Year'"
        [attr.name]="name + 'Year'"
        [name]="name + 'Year'"
        [(ngModel)]="dateRangeField.years"
        (ngModelChange)="handleYearSelection()"
        [itemsObservable]="dateRangeYearsStream"
        [options]="comboboxOptions"
        [placeholder]="'Select year'"
        [disabled]="disabled"
        aria-required="true"
        required
        bfmField
        bfmLabel="Year"
      ></bento-combobox>
    </div> -->

    <!-- <div class="form-group Form-group Form-group--nested" *ngIf="shouldShowMonth">
      <label class="bento-label-required" [attr.for]="id + 'Month'">Month</label>
      <bento-combobox
        [attr.id]="id + 'Month'"
        [attr.name]="name + 'Month'"
        [name]="name + 'Month'"
        [(ngModel)]="dateRangeField.month"
        (ngModelChange)="handleMonthSelection()"
        [itemsObservable]="dateRangeMonthStream"
        [options]="comboboxOptions"
        [placeholder]="'Select month'"
        [disabled]="disabled"
        aria-required="true"
        required
        bfmField
        bfmLabel="Month"
      ></bento-combobox>
    </div>

    <div class="form-group Form-group Form-group--nested" *ngIf="shouldShowQuarter">
      <label class="bento-label-required" [attr.for]="id + 'Quarter'" [id]="id + 'Quarter'">Quarter</label>
      <bento-combobox
        [attr.id]="id + 'Quarter'"
        [attr.name]="name + 'Quarter'"
        [name]="name + 'Quarter'"
        [(ngModel)]="dateRangeField.quarter"
        [itemsObservable]="dateRangeQuarterStream"
        [options]="comboboxOptions"
        [placeholder]="'Select quarter'"
        [disabled]="disabled"
        aria-required="true"
        required
        bfmField
        bfmLabel="Quarter"
      ></bento-combobox>
    </div> -->
  </div>
  <div class="form-group FormRange" *ngIf="isCustomRange">
    <div class="FormRange-group">
      <div class="FormRange-item FormRange-item--input">
        <label for="startDate" id="timeRangeStartLabel">
          <div>Start date*</div>
          <div class="font-weight-normal"><span class="sr-only">Date format:</span>MM/YYYY</div>
        </label>
        <input
          type="text"
          name="startDate"
          id="startDate"
          class="form-control"
          bfmField
          bfmLabel="Start date"
          required
          [(ngModel)]="createChartModel.customRange.startDateText"
          (ngModelChange)="handleFixedDateRangeSelection()"
          [pattern]="validatePattern"
          [validations]="validations.validatePattern"
          (fieldValidate)="isValidDate($event, 'StartDate', DateRangeForm)"
          [disabled]="disabled"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeStartLabel'"
        />
      </div>
      <div class="FormRange-item FormRange-item--input">
        <label for="endDate" id="timeRangeEndLabel">
          <div>End date*</div>
          <div class="font-weight-normal"><span class="sr-only">Date format:</span>MM/YYYY</div>
        </label>
        <input
          type="text"
          name="endDate"
          #endDate
          id="endDate"
          class="form-control"
          bfmLabel="End date"
          bfmField
          required
          [(ngModel)]="createChartModel.customRange.endDateText"
          (ngModelChange)="handleFixedDateRangeSelection()"
          [pattern]="validatePattern"
          [validations]="validations.validatePattern"
          (fieldValidate)="isValidDate($event, 'EndDate', DateRangeForm)"
          [disabled]="disabled"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeEndLabel'"
        />
      </div>
    </div>
    <div>
      <p class="FilterPanel-separator">Compare to</p>
    </div>
    <div class="FormRange-group" *ngIf="shouldShowCompare">
      <div class="FormRange-item FormRange-item--input">
        <label for="startDateCompare" id="timeRangeStartCompareLabel">
          <div>Start date*</div>
        </label>
        <input
          type="text"
          #startDateCompare
          name="startDateCompare"
          id="startDateCompare"
          class="form-control"
          bfmField
          bfmLabel="Start date"
          required
          [ngModel]="createChartModel.customRange.startDateText2"
          [disabled]="true"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeStartCompareLabel'"
        />
      </div>
      <div class="FormRange-item FormRange-item--input">
        <label for="endDateCompare" id="timeRangeEndCompareLabel">
          <div>End date*</div>
        </label>
        <input
          type="text"
          name="endDateCompare"
          #endDateCompare
          id="endDateCompare"
          class="form-control"
          bfmLabel="End date"
          bfmField
          required
          [ngModel]="createChartModel.customRange.endDateText2"
          [disabled]="true"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeEndCompareLabel'"
        />
      </div>
    </div>
  </div>
</form>

<form *ngIf="!showNewFilter" name="DateRangeForm" #DateRangeForm="ngForm" bfmForm>
  <div *ngIf="!isCustomRange">
    <div class="form-group Form-group Form-group--nested">
      <label class="bento-label-required" for="{{ id + 'Year' }}">Year</label>
      <bento-combobox
        id="{{ id + 'Year' }}"
        [attr.name]="name + 'Year'"
        [name]="name + 'Year'"
        [(ngModel)]="dateRangeField.years"
        (ngModelChange)="handleYearSelection()"
        [itemsObservable]="dateRangeYearsStream"
        [options]="comboboxOptions"
        [placeholder]="'Select year'"
        [disabled]="disabled"
        aria-required="true"
        required
        bfmField
        bfmLabel="Year"
      ></bento-combobox>
    </div>

    <div class="form-group Form-group Form-group--nested" *ngIf="shouldShowMonth">
      <label class="bento-label-required" for="{{ id + 'Month' }}">Month</label>
      <bento-combobox
        id="{{ id + 'Month' }}"
        [attr.name]="name + 'Month'"
        [name]="name + 'Month'"
        [(ngModel)]="dateRangeField.month"
        (ngModelChange)="handleMonthSelection()"
        [itemsObservable]="dateRangeMonthStream"
        [options]="comboboxOptions"
        [placeholder]="'Select month'"
        [disabled]="disabled"
        aria-required="true"
        required
        bfmField
        bfmLabel="Month"
      ></bento-combobox>
    </div>

    <div class="form-group Form-group Form-group--nested" *ngIf="shouldShowQuarter">
      <label class="bento-label-required" [attr.for]="id + 'Quarter'" [id]="id + 'Quarter'">Quarter</label>
      <bento-combobox
        [attr.id]="id + 'Quarter'"
        [attr.name]="name + 'Quarter'"
        [name]="name + 'Quarter'"
        [(ngModel)]="dateRangeField.quarter"
        [itemsObservable]="dateRangeQuarterStream"
        [options]="comboboxOptions"
        [placeholder]="'Select quarter'"
        [disabled]="disabled"
        aria-required="true"
        required
        bfmField
        bfmLabel="Quarter"
      ></bento-combobox>
    </div>
  </div>
  <div class="form-group FormRange" *ngIf="isCustomRange">
    <div class="FormRange-group">
      <div class="FormRange-item FormRange-item--input">
        <label for="startDate" id="timeRangeStartLabel">
          <div>Start date*</div>
          <div class="font-weight-normal"><span class="sr-only">Date format:</span>MM/YYYY</div>
        </label>
        <input
          type="text"
          name="startDate"
          id="startDate"
          class="form-control"
          bfmField
          bfmLabel="Start date"
          required
          [(ngModel)]="createChartModel.customRange.startDateText"
          (ngModelChange)="handleFixedDateRangeSelection()"
          [pattern]="validatePattern"
          [validations]="validations.validatePattern"
          (fieldValidate)="isValidDate($event, 'StartDate', DateRangeForm)"
          [disabled]="disabled"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeStartLabel'"
        />
      </div>
      <div class="FormRange-item FormRange-item--input">
        <label for="endDate" id="timeRangeEndLabel">
          <div>End date*</div>
          <div class="font-weight-normal"><span class="sr-only">Date format:</span>MM/YYYY</div>
        </label>
        <input
          type="text"
          name="endDate"
          #endDate
          id="endDate"
          class="form-control"
          bfmLabel="End date"
          bfmField
          required
          [(ngModel)]="createChartModel.customRange.endDateText"
          (ngModelChange)="handleFixedDateRangeSelection()"
          [pattern]="validatePattern"
          [validations]="validations.validatePattern"
          (fieldValidate)="isValidDate($event, 'EndDate', DateRangeForm)"
          [disabled]="disabled"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeEndLabel'"
        />
      </div>
    </div>
    <div>
      <p class="FilterPanel-separator">Compare to</p>
    </div>
    <div class="FormRange-group" *ngIf="shouldShowCompare">
      <div class="FormRange-item FormRange-item--input">
        <label for="startDateCompare" id="timeRangeStartCompareLabel">
          <div>Start date*</div>
        </label>
        <input
          type="text"
          #startDateCompare
          name="startDateCompare"
          id="startDateCompare"
          class="form-control"
          bfmField
          bfmLabel="Start date"
          required
          [ngModel]="createChartModel.customRange.startDateText2"
          [disabled]="true"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeStartCompareLabel'"
        />
      </div>
      <div class="FormRange-item FormRange-item--input">
        <label for="endDateCompare" id="timeRangeEndCompareLabel">
          <div>End date*</div>
        </label>
        <input
          type="text"
          name="endDateCompare"
          #endDateCompare
          id="endDateCompare"
          class="form-control"
          bfmLabel="End date"
          bfmField
          required
          [ngModel]="createChartModel.customRange.endDateText2"
          [disabled]="true"
          [attr.aria-labelledby]="ariaFieldHeadingId + ' timeRangeEndCompareLabel'"
        />
      </div>
    </div>
  </div>
</form>
