<bento-alert [items]="alerts"></bento-alert>
<div class="GlobalLayout-item" [bentoBusyLoader]="isDataLoading">
  <section class="Section Section--dataGrid Section--narrow">
    <header class="Section-header">
      <div class="row">
        <div class="col">
          <app-grid-record-details
            [currentRecords]="currentRecords"
            [totalRecords]="totalRecords"
          ></app-grid-record-details>
        </div>
        <div class="col">
          <div class="Actions justify-content-end">
            <div class="Actions-item Actions-item--large">
              <button type="button" class="btn btn-outline-secondary" (click)="addUser()">
                <span class="btn-text">Add user</span>
              </button>
            </div>
            <div class="Actions-item Actions-item--large">
              <span class="bento-search">
                <label for="userSearch" class="sr-only">Search user</label>
                <input type="text" class="form-control" name="userSearch" id="userSearch" [(ngModel)]="searchText" />
                <button type="button" class="btn btn-primary" (click)="searchUsers()">
                  <span class="btn-text">Search</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="Section-body">
      <div class="sr-only" id="usersTitle">Users</div>
      <wj-flex-grid
        #flexGrid
        class="DataGrid"
        [showMarquee]="true"
        [itemsSource]="userList"
        [autoClipboard]="false"

        [headersVisibility]="'Column'"
        [headersFocusability]="'All'" 
        [allowDragging]="false"
        [autoRowHeights]="true"
        (initialized)="gridInitialized(flexGrid)"
        (sortingColumn)="sortingColumn($event.getColumn().binding)"
        (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
        (keydown)="handleKeyDown(flexGrid, $event)"
        >
        <wj-flex-grid-column
          [header]="'Last name'"
          [binding]="'lastName'"
          [align]="'left'"
          [width]="'*'"
          [minWidth]="240"
          [wordWrap]="true"
          [cellTemplate]="templateNameLink"
        >
          <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            Last name
            <span class="wj-glyph-up" *ngIf="isLastNameAscending"></span>
            <span class="wj-glyph-down" *ngIf="isLastNameAscending === null ? false : !isLastNameAscending"></span>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'First name'"
          [binding]="'firstName'"
          [width]="'*'"
          [minWidth]="240"
          [allowResizing]="false"
          [wordWrap]="true"
        >
          <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            First name
            <span class="wj-glyph-up" *ngIf="isFirstNameAscending"></span>
            <span class="wj-glyph-down" *ngIf="isFirstNameAscending === null ? false : !isFirstNameAscending"></span>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Role'"
          [binding]="'role'"
          [width]="'*'"
          [minWidth]="240"
          [allowResizing]="false"
          [wordWrap]="true"
        >
          <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            Role
            <span class="wj-glyph-up" *ngIf="isRoleAscending"></span>
            <span class="wj-glyph-down" *ngIf="isRoleAscending === null ? false : !isRoleAscending"></span>
          </ng-template>
        </wj-flex-grid-column>
      </wj-flex-grid>
      <bento-pagination
        [(page)]="currentInfo.page"
        (pageChange)="onPageChanged($event)"
        [directionLinks]="true"
        [boundaryLinks]="true"
        [totalItems]="currentInfo.totalItems"
        [itemsArray]="selectItems"
        (itemsPerPageChange)="onItemsPerPageChanged($event)"
        [(itemsPerPage)]="currentInfo.numItems"
      ></bento-pagination>
    </div>
  </section>
</div>
