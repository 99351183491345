import { Component, ViewChild } from '@angular/core';
import { BaseService } from 'src/app/core/services/base/base.service';
import { environment } from 'src/environments/environment';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {GlobalService} from 'src/app/core/services/global/global.service';
import { BentoAlertItemOptions } from '@bento/bento-ng';
import { HttpParams } from '@angular/common/http';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

@Component({
  selector: 'app-one-offs',
  templateUrl: './one-offs.component.html',
  styleUrls: ['./one-offs.component.scss']
})
export class OneOffsComponent {
  isLoadingOneOffs =true;
  data: wjcCore.CollectionView;
  errorMessage : string;
  alerts: BentoAlertItemOptions[] = [];
  gridsSelectedRow =0;
  gridsSelectedCol =0;
  lastPressedKey: any;
  templateOneoffLink = CellMaker.makeLink({
    text: '${item.name}',
    label:'Link: ${item.name}',
    click:  (e, ctx) => this.downloadResourceFile(ctx.item.link, ctx.item.name, ctx.item.extension),
    attributes: {
        tabIndex: -1
    }
  });

  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  constructor(private service: BaseService,
    private globalservice: GlobalService,
    private downloadFile: DownloadService,
  ){}
  ngOnInit() {
    this.getOneOffsList();
  }
  getOneOffsList()
  {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/storage/oneoffs/listFiles', '').subscribe(
      (result) => {
        this.data = new wjcCore.CollectionView(result.oneoffs);
        
        //added for skip 2 focus betno combobox for accessibility fix
        this.globalservice.skipFocusTwiceCombobox();
        this.isLoadingOneOffs = false;
      },
      (error) => {
        this.isLoadingOneOffs = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: this.errorMessage,
          timeout: 2500,
          closeable: true,
        });
      }
    );
  }
  downloadResourceFile(link, name, extension){
    let params = new HttpParams();
    params = params.set('blobName', link);
    params = params.set('fileName', name);
    params = params.set('containerName', 'oneOffs');
    this.service.downloadExcel(environment.FIClientBaseEndpoint + 'v1/storage/downloadFile?' + params).subscribe(
      (response) => {
        this.downloadFile.onlyDownloadWithExtension(
          response.body,
          name,
          extension
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  gridInitialized(flexGrid: wjcGrid.FlexGrid) {      
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 100;
      flexGrid.columnHeaders.rows.defaultSize = 40;
      // flexGrid.select(-1,-1);
    }
    this.flexGrid = flexGrid;
    flexGrid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });
  }
  focusOnGrid(flexGrid : wjcGrid.FlexGrid,event){
    flexGrid.select(0,0);
  }
  
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    this.gridsSelectedCol = args.col
    this.gridsSelectedRow =args.row;  
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }

  handleKeyDown(flexGrid, event){
    if (event.code === 'Space'  ){ 
      event.preventDefault(); 
      let cell = flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
      let input = cell.querySelector('a');
      input.click();    
    }
    
  } 
}
