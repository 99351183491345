<div [bentoBusyLoader]="isExpandDataLoading">
  <p *ngIf="isCANfirm && !isFromExpandTableView" class="note-info">Note - The Benefits and Compensation Categories below will not be displayed for at least three months following the end of the fiscal year to which it relates.</p>
  <div *ngIf="showExpand">
    <wj-flex-grid
      #flexGrid
      class="DataGrid DataGrid--views--pivot"
      headersVisibility="Column"
      [itemsSource]="mainData"
      [allowMerging]="'ColumnHeaders'"
      [alternatingRowStep]="0"
      [autoGenerateColumns]="false"
      [allowDragging]="false"
      [isReadOnly]="true"
      [frozenColumns]="isExpandTableOffices() ? 4 : frozenColumnCount"
      [columnGroups]="columnGroups"
      [autoRowHeights]="true"
      [itemFormatter]="itemFormatter"
      [selectionMode]="'Cell'"
      [showMarquee]="true"
      [virtualizationThreshold]="5000"
      [headersFocusability]="'All'"
      (initialized)="onInitialized(flexGrid)"
      (keydown)="handleKeyDown(flexGrid, $event)"
      (gotFocus)="focusOnGrid(flexGrid, $event)"
      [allowSorting]="'None'"
    ></wj-flex-grid>
  </div>
  <ng-template #listOfFirmsModal let-c="close" let-d="dismiss">
    <button type="button" class="close" aria-label="Close"  (click)="closePGPopup()">
      <i class="bento-icon-close-x" aria-hidden="true"></i>
    </button>
    <h3 id="modalPeerGroup" class="modal-header h2">
      {{ peerGroupDetail.selectedPeerGroup.name }}
    </h3>
    <div class="modal-body">
      <ol class="OrderedList" *ngIf="!peerGroupDetail.is_ppgRequest">
        <li *ngFor="let firm of peerGroupDetail.selectedFirms; index as sequence">{{ firm.name }}</li>
      </ol>
      <ol class="OrderedList" *ngIf="peerGroupDetail.is_ppgRequest">
        <li *ngFor="let criteria of peerGroupDetail.ppgCriteria">{{ criteria }}</li>
      </ol>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="closePGPopup()">Close</button>
    </div>
  </ng-template>
</div>
